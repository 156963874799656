import { Component, Input, OnInit } from '@angular/core';
import { ICardsExtract } from '../../models/cards-extract.model';

@Component({
  selector: 'app-card-extract-signature-and-shipping-details',
  templateUrl: './card-extract-signature-and-shipping-details.component.html',
  styleUrls: ['./card-extract-signature-and-shipping-details.component.scss'],
})
export class CardExtractSignatureAndShippingDetailsComponent implements OnInit {
  cardAmounts: string[] = [];
  cardContents: string[] = [];
  cardIcons: string[] = [];

  public isSmallScreen: boolean = false;

  @Input() cards: ICardsExtract[];

  @Input() viewCardExtract: string;

  constructor() {}

  ngOnInit(): void {
    this.onViewCardExtract(this.viewCardExtract);
    this.isSmallScreen = window.innerWidth <= 576;
  }

  onViewCardExtract(type: string): any {
    const types: any = {
      extractSignature: () => this.extractSignature(),
      shippingDetails: () => this.shippingDetails(),
    };
    return types[type]();
  }

  private extractSignature(): void {
    this.cardAmounts = ['1', '7', '0'];
    this.cardContents = [
      ' Documentos enviados:',
      'Documentos assinados:',
      ' Assinaturas:',
    ];
    this.cardIcons = ['np-outgoing-mail', ' np-edit', 'np-edit-text'];
  }
  private shippingDetails(): void {}
}
