<div class="modal-delete m-4 d-flex justify-content-around flex-column" ngbAutofocus>
  <div>
    <div
      class="col-12 d-flex justify-content-end Heading16"
      (click)="onCancel()"
    >
      <i class="np np-close btn-icon-close"></i>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 d-flex justify-content-center">
      <app-avatar [icon]="viewModalDelete.iconAvatar" color="danger"></app-avatar>
    </div>
  </div>
  <div>
    <div class="row mb-2">
      <div class="col-12">
        <span class="d-flex justify-content-center Heading20">{{
          viewModalDelete.title
        }}</span>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 text-center wbody14">
        <span class="d-flex justify-content-center">
          {{ viewModalDelete.text }}
        </span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 body14 d-flex justify-content-center">
      <button class="btn btn-secondary mr-2" (click)="onCancel()">
        <i class="np-error mr-2 iconBody18"></i>
        Cancelar
      </button>
      <button
        class="btn btn-danger"
        *ngIf="viewModalDelete.btnSave == 'confirmationDeleteTag'"
        (click)="onConfirm()"
      >
        <i class="np-trash mr-2 iconBody18"></i>
        {{ viewModalDelete.btnSaveText }}
      </button>
      <button
        class="btn btn-primary"
        *ngIf="viewModalDelete.btnSave == 'alertUserNotDelete'"
        (click)="onConfirm()"
      >
        {{ viewModalDelete.btnSaveText }}
        <i class="np-arrow-right"></i>
      </button>
    </div>
  </div>
</div>
