import { Injectable } from '@angular/core';

import { Certificate } from 'src/app/shared/classes/certificate';
import { LacunaWebPkiService } from 'src/app/shared/components/lacuna-web-pki/lacuna-web-pki.service';
import { LoadingSignatureService } from 'src/app/shared/components/settings/loading-signature/loading-signature.service';
import { SignStatusEnum } from 'src/app/shared/enum/signStatusEnum';
import { TipoPessoaLetraEnum } from 'src/app/shared/enum/tipoPessoaEnum';
import SignatureDocumentDigital from 'src/app/shared/models/signatureDocmentDigital';
import SignatureInvolved from 'src/app/shared/models/signatureInvolved';
import { SignatureHttpService } from '../signature.http.service';

@Injectable()
export class SignatureDigitalService {
    constructor(
        private signatureHttpService: SignatureHttpService,
        private lacunaService: LacunaWebPkiService,
        private loadingSignatureService: LoadingSignatureService,
    ) { }

    public async execute(pDocumentosComInvolvido: SignatureDocumentDigital[], pCertificado: Certificate): Promise<void> {
        try {
            if (pCertificado.isExpired()) {
                throw new Error('Certificado vencido');
            }

            const documentosAssinar = this.getDocumentosAssinarDigital(pDocumentosComInvolvido, pCertificado);
            const qtdTotalAssinatura = this.getQtdAssinaturasTotal(documentosAssinar);

            this.loadingSignatureService.showLoader('DIGITAL', qtdTotalAssinatura);
            await this.lacunaService.iniciaPreAutorizacaoAssinatura(pCertificado, qtdTotalAssinatura);
            const encodedCert = await this.lacunaService.retornaCertificadoEncoded(pCertificado);

            await this.assinarDocumentos(documentosAssinar, pCertificado, encodedCert);
            this.loadingSignatureService.hideLoader();
            return Promise.resolve();
        } catch (error) {
            this.loadingSignatureService.hideLoader();
            return Promise.reject(error);
        }
    }
    private async assinarDocumentos(pDocumentos: SignatureDocumentDigital[], pCertificado: Certificate, pCertEncoded: string): Promise<void> {
        try {
            const documentosParalelos: Promise<any>[] = [];
            let primeiroDocumento: boolean = true;
            for (let docPosition = 0; docPosition < pDocumentos.length; docPosition += 1) {
                const documentoAtual = pDocumentos[docPosition];

                if (primeiroDocumento) {
                    await this.assinarDocumento(documentoAtual, pCertificado, pCertEncoded);
                    primeiroDocumento = false;
                } else {
                    documentosParalelos.push(this.assinarDocumento(documentoAtual, pCertificado, pCertEncoded));
                }
            }

            await Promise.all(documentosParalelos);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    private async assinarDocumento(pDocumento: SignatureDocumentDigital, pCertificado: Certificate, pCertEncoded: string): Promise<void> {
        try {
            for (const envolvido of pDocumento.involveds) {
                for (let i = 0; i < envolvido.qtdAssinaturasRealizar; i += 1) {
                    await this.assinar(pDocumento, envolvido, pCertificado, pCertEncoded);
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    private async assinar(pDocumento: SignatureDocumentDigital, pInvolved: SignatureInvolved, pCertificado: Certificate, pCertEncoded: string): Promise<void> {
        try {
            const startResponse = await this.signatureHttpService.assinarDigitalStart({
                certificate: pCertEncoded,
                guid: pDocumento.docGuid,
                involvedGuid: pInvolved.guid,
                signatureId: pInvolved.signatureId,
            });
            if (startResponse.data === undefined) {
                throw new Error('Não foi possivel iniciar a assinatura do documento');
            }

            const assinaturaLacunaResponse = await this.lacunaService.assinarDocumento(pCertificado, startResponse.data);
            if (assinaturaLacunaResponse.sucesso === false || assinaturaLacunaResponse.assinatura === undefined) {
                await this.signatureHttpService.assinarDigitalCancelar({ guid: pDocumento.docGuid });
                throw new Error(assinaturaLacunaResponse.err || '');
            }

            const completeResponse = await this.signatureHttpService.assinarDigitalComplete({
                docGuid: pDocumento.docGuid,
                involvedGuid: pInvolved.guid,
                signatureId: pInvolved.signatureId,
                transferFile: startResponse.data.transferFile,
                signature: assinaturaLacunaResponse.assinatura,
                certificate: pCertificado.rawCertificate.pkiBrazil,
            });

            if (completeResponse.success === false) {
                throw new Error(completeResponse.message ?? 'Não foi possivel iniciar a assinatura do documento');
            }

            this.loadingSignatureService.incrementQtdSignature();
            return Promise.resolve();
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    private validarDigital(pInvolved: SignatureInvolved, pCertificado: Certificate): void {
        const isCnpjCertIgualInvolvedCnpj = pCertificado.cnpj === pInvolved.cnpj;
        const isCpfCertIgualInvolvedCpf = pCertificado.cpf === pInvolved.cpf;
        const isCnpjCertIgualInvolvedCpf = pCertificado.cnpj === pInvolved.cpf;
        const isCnpjEmpresaCpfCertInvolvedIgualInvolved = isCnpjCertIgualInvolvedCnpj && isCpfCertIgualInvolvedCpf;

        if (isCnpjEmpresaCpfCertInvolvedIgualInvolved === false && isCpfCertIgualInvolvedCpf === false && isCnpjCertIgualInvolvedCpf === false) {
            throw new Error(this.gerarErroCertificado(pCertificado, pInvolved));
        }

        const isNaoAssinado = pInvolved.status === SignStatusEnum.naoAssinado;
        if (pCertificado.isPhysicalPerson) {
            const isAssinantePf = pInvolved.signerType === TipoPessoaLetraEnum.pessoaFisica;
            const isAssinantePjComECPF =
                pInvolved.signerType === TipoPessoaLetraEnum.pessoaJuridica
                && !!pInvolved.useECPF === true && !!pInvolved.useECNPJ === false;

            const isPermiteAssinar = isNaoAssinado && (isAssinantePf || isAssinantePjComECPF);
            if (isPermiteAssinar === false) {
                throw new Error('Atenção: Assinatura não permite com e-CPF, assine com e-CNPJ');
            }
        }
        if (pCertificado.isLegalEntity) {
            const permiteAssinar = isNaoAssinado && pInvolved.signerType === 'J' && !!pInvolved.useECPF === false;
            if (permiteAssinar === false) {
                throw new Error('Atenção: Assinatura não permite com e-CNPJ, assine com e-CPF');
            }
        }
    }

    private getDocumentosAssinarDigital(pDocumentos: SignatureDocumentDigital[], pCertificado: Certificate): SignatureDocumentDigital[] {
        const documentosAssinarComEnvolvidos: SignatureDocumentDigital[] = [];
        for (const documento of pDocumentos) {
            const involveds: SignatureInvolved[] = [];
            for (const involved of documento.involveds) {
                const isDocumentoNaoAssinar = documento.assinar === false;
                const isDocumentoJaAssinado = involved.status !== SignStatusEnum.naoAssinado;
                if (isDocumentoJaAssinado || isDocumentoNaoAssinar) {
                    continue;
                }
                this.validarDigital(involved, pCertificado);

                let isAssinar: boolean = false;
                if (pCertificado.isLegalEntity && involved.signerType === TipoPessoaLetraEnum.pessoaJuridica) {
                    isAssinar = true;
                }
                if (pCertificado.isPhysicalPerson && involved.useECNPJ === false) {
                    isAssinar = true;
                }
                if (isAssinar) {
                    involveds.push(Object.assign({}, involved));
                }
            }
            if (involveds.length) {
                documentosAssinarComEnvolvidos.push(new SignatureDocumentDigital(documento.docGuid, documento.nomeDocumento, documento.status, true, involveds));
            }
        }
        return documentosAssinarComEnvolvidos;
    }

    private getQtdAssinaturasTotal(pDocumentosAssinar: SignatureDocumentDigital[]): number {
        let qtd: number = 0;
        pDocumentosAssinar.forEach(pDoc => pDoc.involveds.forEach(pInv => qtd += pInv.qtdAssinaturasRealizar));
        return qtd;
    }

    private gerarErroCertificado(pCertificado: Certificate, pEnvolvido: SignatureInvolved): string {
        const cpfInvolved = '***' + pEnvolvido.cpf.substring(3, 12);
        const cpfCertifate = '***' + pCertificado.cpf.substring(3, 12);

        let mensagem = '<span style="font-size:14px">O certificado escolhido não representa o envolvido.</span>';
        mensagem += '<div class="pt-3 pb-3" style="font-size:12px;border-top: 1px solid #B9C0C7;border-bottom: 1px solid #B9C0C7;">';
        mensagem += '<span>Envolvido a assinar: <strong>CPF ' + cpfInvolved;
        if (pEnvolvido.cnpj != null) {
            const cnpjInvolved = '****' + pEnvolvido.cnpj.substring(4, 20);
            mensagem += ', CNPJ ' + cnpjInvolved;
        }
        mensagem += '</strong></span>';

        mensagem += '<br><span>Certificado selecionado:<strong>CPF ' + cpfCertifate;
        if (pCertificado.cnpj != null) {
            const cnpjCertificate = '****' + pCertificado.cnpj.substring(4, 20);
            mensagem += ', CNPJ ' + cnpjCertificate;
        }
        mensagem += '</strong></span>';
        mensagem += '</div>';
        mensagem += '<div style="font-size:12px">Selecione um certificado que tenha os dados do envolvido ou solicite um novo envio com os dados corretos do envolvido para quem solicitou sua assinatura.</div>';
        return mensagem;
    }
}
