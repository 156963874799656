<div class="app-modal-confirm-delete-tag p-3 px-md-4 py-3" ngbAutofocus>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
            <a (click)="closeModal(false)">
                <i class="iconBody22 np np-close-doc np np-close-doc color-gray-300"></i>
            </a>
        </div>
        <div class="col-md-12 d-flex justify-content-center pb-2">
            <app-avatar color="danger" icon="np-trash"></app-avatar>
        </div>
        <div class="col-md-12 d-flex justify-content-center my-2">
            <span class="Heading20 text-center">
                <span class="Heading20 d-flex justify-content-center mb-2">Deseja excluir marcador?</span>
                <span class="d-flex justify-content-center Heading14">Essa ação não poderá ser revertida.</span>
                <span class="Heading14">Marcador associado a um ou mais documentos!</span>
            </span>
        </div>
        <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
            <button class="wbody14 btn btn-secondary mr-2 altura" (click)="closeModal(false)">
                <i class="iconBody18 mr-2 np np-error"></i> Cancelar
            </button>
            <button class="wbody14 btn btn-danger altura" (click)="closeModal(true)">
                <i class="iconBody18 mr-2 np np-trash"></i> Sim, excluir
            </button>
        </div>
    </div>
</div>