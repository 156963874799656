import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('inputSearch') inputSearch: ElementRef

  @Input() placeholder: string = '';
  @Output() eventSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() expandirInput = new EventEmitter<boolean>();

  public searchFormControl: FormControl = new FormControl('');
  private isOpen = false;
  private isFocusing = false;
  public tamanhoInput: number = 0;

  constructor(private elRef: ElementRef, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    this.tamanhoInput = this.inputSearch.nativeElement.offsetWidth;
    this.cdr.detectChanges();
  }

  public limparPesquisa(): void {
    this.searchFormControl.reset();
    this.eventSearch.emit(this.searchFormControl.value);
  }

  public onFocus(): void {
    this.isOpen = true;
    this.expandirInput.emit(this.isOpen);

    // Ativar um flag de foco temporário
    this.isFocusing = true;
    setTimeout(() => (this.isFocusing = false), 200); // Pequeno delay para evitar conflito
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.isFocusing && !this.elRef.nativeElement.contains(event.target) && this.isOpen) {
      this.isOpen = false;
      this.expandirInput.emit(this.isOpen);
    }
  }

}
