export class FormValidations {
  static getErrorMsg(
    fieldName: string,
    validatorName: string,
    validatorValue?: any,
  ): string {
    const config: { [key: string]: string } = {
      required: `Campo obrigatório`,
      maxlength: `${fieldName} precisa ter no máximo ${validatorValue.requiredLength} caracteres.`,
      max: `Valor máximo ${validatorValue.max}.`,
      confirmPasswordNotMatch: 'Senhas diferentes',
      numberNegativeInvalid: validatorValue,
      numberInvalid: validatorValue,
      notNumberNegativeInvalid: validatorValue,
      rangeMinimumInvalid: validatorValue,
      rangeMaximumInvalid: validatorValue,
      numberNotFloatInvalid: validatorValue,
      securityCodeInvalido: 'O código de acesso inválido',
      email: 'Digite um e-mail válido',
      minlength: `Digite ${fieldName} com mais de ${validatorValue.requiredLength - 1} caracteres`,
      cpfCnpjInvalid: 'Digite um CPF/CNPJ válido',
      cpfInvalid: 'Digite um CPF válido',
      cnpjInvalid: 'Digite um CNPJ válido',
      telefoneInvalid: 'Digite um telefone válido',
      nameTitleAlreadyRegistered: 'Nome de título já cadastrado, inserir outro nome!',
      passwordNotConfer: 'A confirmação de senha não confere'
    };

    return config[validatorName] || 'Campo não corresponde ao requerido';
  }
}
