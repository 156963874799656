<div class="w-100" #inputSearch>
  <div
    class="inner-addon left-addon d-flex align-items-center two-addon-in-same-input"
  >
    <i class="np np-search color-gray-300 size-icon"></i>
    <input
      class="input input-search"
      type="text"
      [formControl]="searchFormControl"
      [placeholder]="placeholder"
      (keyup)="eventSearch.emit(searchFormControl.value)"
      (focus)="onFocus()"
    />
    <div
      *ngIf="tamanhoInput >= 100"
      class="d-flex align-items-center icon-close"
    >
      <i
        class="np-error color-gray-300 size-icon"
        (click)="limparPesquisa()"
      ></i>
    </div>
  </div>
</div>
