import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LayoutVisaoEnum } from 'src/app/shared/enum/layout-visao.enum';
import { ITag } from 'src/app/shared/models/paper.model';
import { ITagCreate, ITagUpdate, TagsModel } from 'src/app/shared/models/tagsModel';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { ModalConfirmDeleteTagService } from '../../modal-confirm-delete-tag/modal-confirm-delete-tag.service';
import { ToasterService } from '../../toaster/toaster.service';
import { ModalTagService } from '../modal-tag/modal-tag.service';
import { TagService } from './../tag.service';
import { ValueCheckdEvent } from 'src/app/shared/models/BehaviorCheckboxRadioInput';

@Component({
  selector: 'app-list-tag',
  templateUrl: './list-tag.component.html',
  styleUrls: ['./list-tag.component.scss'],
})
export class ListTagComponent implements OnInit {
  @ViewChild(NgbDropdown) private dropdown: NgbDropdown;
  @Output() eventTag = new EventEmitter<ITag[] | null>();
  @Output() eventAtualizacaoTag: EventEmitter<null> = new EventEmitter();

  typeListTag: string = 'listTag'; //or onlySelectListTag
  public currentTags: TagsModel[] = [];
  public loading: boolean = false;
  public tagColor: string = '#334EAE';
  public formTag: FormGroup;
  public formCheck: FormControl = new FormControl(false);
  public isVisaoMobile: boolean = false;
  public tagsSelected: ITag[] = [];

  constructor(
    private tagService: TagService,
    private formBuilder: FormBuilder,
    private modalTag: ModalTagService,
    private toastrService: ToasterService,
    private layoutVisao: LayoutService,
    private modalConfirmDeleteTag: ModalConfirmDeleteTagService
  ) {
    this.formTag = this.formBuilder.group({
      checkTags: this.formBuilder.array([], [Validators.required]),
    });
  }


  ngOnInit(): void {
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE);
    this.carregarTags();
  }

  private async carregarTags() {
    this.loading = false;
    this.currentTags = await this.tagService.getTags();
    this.loading = true;
    this.currentTags.forEach(tag => {
      tag.isChecked = false;
    });
  }

  public clearTags(): void {
    this.tagColor = '#334EAE';
    this.eventTag.emit(null);
    this.tagsSelected = [];
    this.formCheck.setValue(false);
    this.currentTags.forEach(tag => {
      tag.isChecked = false;
    });
    this.dropdown.close();
  }

  public isCheckedItem(itemChecked: boolean | undefined, tag: TagsModel) {
    if (itemChecked) {
      tag.isChecked = true;
      this.tagsSelected.push({ tagId: tag.tagId });
      this.eventTag.emit(this.tagsSelected);
    } else {
      tag.isChecked = false;

      const indexOf = this.tagsSelected.findIndex(field => field.tagId === tag.tagId);

      this.tagsSelected.splice(indexOf, 1);
      this.eventTag.emit(this.tagsSelected);
    }
  }

  public async onDelete(index: number) {
    const result = await this.modalConfirmDeleteTag.open();
    if (result) {
      this.onSubmitConfirmDelete(index);
    }
  }

  public onModalTagSign(): void {
    this.modalTag.modalTagSign().subscribe((result) => {
      if (result.success) {
        this.onSubmitConfirmInclusao(result.tag);
      }
    });
  }

  public onMoalTagEdit(index: number): void {
    this.modalTag.modalTagEdit(this.currentTags[index]).subscribe((result) => {
      if (result.success) {
        this.onSubmitConfirmAlteracao(result.tag, this.currentTags[index].tagId);
      }
    });
  }

  onCheckboxChange(event: any) {
    const checkTags: FormArray = this.formTag.get('checkTags') as FormArray;
    if (event.target.checked) {
      checkTags.push(new FormControl(event.target.value));
    } else {
      let i: number = 0;
      checkTags.controls.forEach((item: any) => {
        if (item.value == event.target.value) {
          checkTags.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  submitForm() {
    this.dropdown.close();
  }

  private async onSubmitConfirmInclusao(tag: ITagCreate) {
    try {
      await this.tagService.postTag(tag);
      this.toastrService.showSuccess('Marcador cadastrado com sucesso!');

      this.carregarTags();
    } catch (error) {
      this.toastrService.showError(
        'Não foi possivel cadastrar o marcador',
      );
    }
  }

  private async onSubmitConfirmAlteracao(tag: ITagUpdate, tagId: number) {
    try {
      await this.tagService.putTag(tagId, tag);
      this.eventAtualizacaoTag.emit();
      this.toastrService.showSuccess('Marcador alterado com sucesso!');
      this.carregarTags();
    } catch (error) {
      this.toastrService.showError(
        'Não foi possivel alterar o marcador',
      );
    }
  }

  private async onSubmitConfirmDelete(index: number) {
    try {
      await this.tagService.deleteTag(this.currentTags[index].tagId);
      this.toastrService.showSuccess('Marcador excluído com sucesso!');
      this.currentTags[index].status = 'delete';
      this.eventTag.emit(this.tagsSelected);
      this.eventAtualizacaoTag.emit();

      this.carregarTags();
    } catch (error) {
      this.toastrService.showError(
        'Não foi possivel excluir o marcador',
      );
    }
  }
}
