import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NavNService } from 'src/app/core/auth-layout/components/nav/nav.service';
import { NotAuthLayoutService } from 'src/app/core/not-auth-layout/not-auth-layout.service';
import { AssinarDocumentoStorageService } from 'src/app/modules/assinar-documento/assinar-documento.storage.service';
import { ModalAssinaturaService } from 'src/app/modules/assinar-documento/components/modal-assinatura/modal-assinatura.service';
import { ModalAtualizarAssinaturaService } from 'src/app/modules/assinar-documento/components/modal-atualizar-assinatura/modal-atualizar-assinatura.service';
import { ModalConfirmarInicioAssinaturaService } from 'src/app/modules/assinar-documento/components/modal-confirmar-inicio-assinatura/modal-confirmar-inicio-assinatura.service';
import { ModalSucessoAssinaturaComponentService } from 'src/app/modules/assinar-documento/components/modal-sucesso-assinatura/modal-sucesso-assinatura.component.service';
import { ModalSelectSignatureTypeService } from 'src/app/modules/new-documents/components/modal-select-signature-type/modal-select-signature-type.service';
import { Certificate } from 'src/app/shared/classes/certificate';
import { LacunaWebPkiService } from 'src/app/shared/components/lacuna-web-pki/lacuna-web-pki.service';
import { LoadingSignatureService } from 'src/app/shared/components/settings/loading-signature/loading-signature.service';
import { LoadingService } from 'src/app/shared/components/settings/loading/loading.service';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { TypeEventCardEnum } from 'src/app/shared/enum/event-card.enum';
import { LayoutVisaoEnum } from 'src/app/shared/enum/layout-visao.enum';
import { NewDocumentsOptionsEnum } from 'src/app/shared/enum/new-documents';
import { SignatureTypeEnum } from 'src/app/shared/enum/signature-type.enum';
import { SignStatusEnum } from 'src/app/shared/enum/signStatusEnum';
import { TypeSignatureEnum } from 'src/app/shared/enum/type-signature';
import { ValueCheckdEvent } from 'src/app/shared/models/BehaviorCheckboxRadioInput';
import { EletronicSignData } from 'src/app/shared/models/eletronicSignData';
import { IEventCard } from 'src/app/shared/models/Event-card.model';
import { ISelectOptionAssunto } from 'src/app/shared/models/input';
import { KeyLocalStorage } from 'src/app/shared/models/key-local-storage';
import { IPaper, IPaperDescartados } from 'src/app/shared/models/paper.model';
import SignatureDocumentDigital from 'src/app/shared/models/signatureDocmentDigital';
import SignatureDocument from 'src/app/shared/models/signatureDocument';
import SignatureInvolved from 'src/app/shared/models/signatureInvolved';
import { IUserData } from 'src/app/shared/models/user.model';
import { IValorLabel } from 'src/app/shared/models/valor-label';
import { DocumentHttpService } from 'src/app/shared/services/document/document.http.service';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { PaperHttpService } from 'src/app/shared/services/paper/paper.http.service';
import { SignatureDigitalService } from 'src/app/shared/services/signature/digital/signature-digital.service';
import { SignatureEletronicService } from 'src/app/shared/services/signature/eletronic/signature-eletronic.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import TagUtil from 'src/app/shared/utils/paper-tag.util';
import { SecretUtil } from 'src/app/shared/utils/secret-util';
import { NewDocumentsReduxService } from '../../../new-documents/new-documents.redux.service';

@Component({
  selector: 'app-batch-sign',
  templateUrl: './batch-sign.component.html',
  styleUrls: ['./batch-sign.component.scss']
})
export class BatchSignComponent implements OnInit, OnDestroy {
  public LIST_SUBSCRIBERS: NewDocumentsOptionsEnum = NewDocumentsOptionsEnum.LIST_SUBSCRIBERS;
  public certificates: Certificate[] = [];
  public MESSAGE_CASE_NOT_WEB_PKI_INSTALLED: string = 'Para realizar o login com certificado é preciso a extensão Web PKI em seu navegador. Ao clicar em instalar você será redirecionado para a tela com o passo a passo da instalação.';
  public papers: IPaper[] = [];
  searchs: string[] = this.navbarService.getSearch();
  public ocultarSearch: boolean = false;
  public existePapers: boolean = false;
  public signatureType: string = '';
  public papersSelected: number[] = [];
  public certificado: Certificate;
  private assinaturaCadastrada: string | null = null;
  public isVisaoMobile: boolean = false;
  public isVisaoTablet: boolean = false;
  public userAuth: IUserData | null = null;

  public formCheck: FormControl;
  public eventPaper = new EventEmitter<ValueCheckdEvent>();

  private papersSearch: Array<IPaper> = new Array();
  public formControlSelectFilterInAssunto: FormControl;
  public openInputSearchAssunto: boolean = false;

  constructor(
    private loaderService: LoadingService,
    private toasterService: ToasterService,
    public newDocumentsService: NewDocumentsReduxService,
    private modalSelectSignatureTypeService: ModalSelectSignatureTypeService,
    private lacunaWebPkiService: LacunaWebPkiService,
    private authLayoutService: NotAuthLayoutService,
    private paperHttpService: PaperHttpService,
    private router: Router,
    private navbarService: NavNService,
    private localStorageService: LocalStorageService,
    private documentHttpService: DocumentHttpService,
    private assinarDocumentoStorageService: AssinarDocumentoStorageService,
    private signatureDigitalService: SignatureDigitalService,
    private userHttpService: UserService,
    private modalAssinaturaService: ModalAssinaturaService,
    private modalConfirmarInicioAssinaturaService: ModalConfirmarInicioAssinaturaService,
    private loaderAssinatura: LoadingSignatureService,
    private modalSucessoService: ModalSucessoAssinaturaComponentService,
    private signatureEletronicService: SignatureEletronicService,
    private modalAtualizarAssinaturaService: ModalAtualizarAssinaturaService,
    private layoutVisao: LayoutService
  ) {
    this.formCheck = new FormControl(false);
    this.formControlSelectFilterInAssunto = new FormControl(null);
  }

  async ngOnInit(): Promise<void> {
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE);
    this.isVisaoTablet = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.TABLET);

    this.ocultarSearch = false;
    if (window.innerWidth <= 768) {
      this.ocultarSearch = true;
    }
    this.selectOptionNewDocuments();
  }

  ngOnDestroy(): void {
    this.authLayoutService.showMenuAction();
    this.newDocumentsService.resetReduxNewDocuments();
  }

  public onFocusInputSearchAssunto(event: any): void {
    this.openInputSearchAssunto = event;
  }

  public onAssuntoSelect(event: IValorLabel): void {
    const resultConvertIdStringInNumber = Number(event.valor);
    const paperFilter: IPaper[] = [];
    this.papersSearch.forEach(paper => {
      if (resultConvertIdStringInNumber === paper.paperId) {
        paperFilter.push(paper);
      }
    });

    this.preencherCard(paperFilter);
  }

  private preencherCard(papers: IPaper[]): void {
    this.papers = papers;

    this.papers.forEach(paper => {
      paper.signatureType = TagUtil.formatTypeSignature(paper.signatureType);
    });
  }

  public async searchDocumentAssunto(valueSearch: string): Promise<void> {
    try {
      if (this.signatureType === TypeSignatureEnum.DIGITAL) {
        await this.getPapersDigital(this.certificado, valueSearch);
      } else {
        await this.getPapersEletronica(valueSearch);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public modificarCertificado(): void {
    this.ngOnDestroy();
    this.selectOptionNewDocuments();
  }

  public changeChecked(values: ValueCheckdEvent): void {
    this.formCheck.setValue(values.value);
    this.eventPaper.emit(values);
  }

  public async selectOptionNewDocuments(): Promise<void> {
    try {
      this.loaderService.showLoader();
      await this.loadingCertificate();

      if (this.LIST_SUBSCRIBERS) {
        this.loaderService.hideLoader();
        const retorno: any = await this.selectTypeSignature();
        this.newDocumentsService.setTypeSignature(retorno.tipo, true);
        this.loaderService.showLoader();

        this.signatureType = TagUtil.formatTypeSignature(retorno.tipo);
        this.certificado = retorno.certificado;

        this.getPapersAssinatura(retorno);
      }


    } catch (error: any) {
      this.toasterService.showError(error.message || 'Erro ao carregar dados do usuário');
      throw new Error('Erro ao carregar dados do usuário');
    }
    finally {
      this.loaderService.hideLoader();
    }
  }

  private async selectTypeSignature(): Promise<SignatureTypeEnum> {
    if (this.newDocumentsService.signatureType === SignatureTypeEnum.BOTH) {
      return await this.openModalSelectSignatureType();
    }
    return Promise.resolve(this.newDocumentsService.signatureType);
  }

  private async openModalSelectSignatureType(): Promise<SignatureTypeEnum> {
    return Promise.resolve(await this.modalSelectSignatureTypeService.open('batch-sign', this.certificates));
  }

  private async loadingCertificate(): Promise<void> {
    this.lacunaWebPkiService.messageModalNotInstalled = this.MESSAGE_CASE_NOT_WEB_PKI_INSTALLED;
    try {
      this.certificates = await this.lacunaWebPkiService.getCertificates();
    } catch (error: any) {
      this.toasterService.showInfo(error.message);
      return;
    }
  }

  private getPapersAssinatura(retorno: any): void {
    if (retorno.tipo === 'P') {
      this.getPapersDigital(retorno.certificado);
    } else {
      this.getPapersEletronica();
      this.carregaAssinaturaCadastrada();
    }
  }

  private async getPapersDigital(ceritifcado: Certificate, pSearchValue?: string): Promise<void> {
    try {
      const papersDb = await this.paperHttpService.getPapersLoteCertificadoDigital(ceritifcado.cpf, ceritifcado.cnpj, pSearchValue);
      this.papersSearch = papersDb;
      this.existePapers = papersDb.length > 0;
      this.preencherCard(this.papersSearch);
    } catch (error) {
      this.toasterService.showError('Não foi possível buscar os documentos a serem assinados');
    }
  }

  private async getPapersEletronica(pSearchString?: string): Promise<void> {
    try {
      this.userAuth = this.getUserLocal();

      if (this.userAuth === null) {
        return Promise.resolve();
      }

      const papersDb = await this.paperHttpService.getPapersLoteAssinaturaEletronica(this.userAuth.identification, pSearchString);
      this.papersSearch = papersDb;
      this.preencherCard(this.papersSearch);
      this.existePapers = papersDb.length > 0;
    } catch (error) {
      this.toasterService.showError('Não foi possível buscar os documentos a serem assinados');
    }
  }

  private async carregaAssinaturaCadastrada(): Promise<void> {
    if (this.localStorageService.get(KeyLocalStorage.NPAPER_AUTH_TOKEN) !== null) {
      const assinaturaCadastrada = await this.userHttpService.getUserSign();
      if (assinaturaCadastrada?.user?.signatureImage) {
        this.assinaturaCadastrada = assinaturaCadastrada.user.signatureImage;
      }
    }
  }

  public changeCheckedChildren(bodyChildren: { id: number, values: ValueCheckdEvent }): void {
    if (bodyChildren.values.value) {
      this.papersSelected = [...this.papersSelected, bodyChildren.id];
      this.papersSelected = this.papersSelected.filter((id, index) => this.papersSelected.indexOf(id) === index);
    } else {
      this.papersSelected = this.papersSelected.filter(value => value !== bodyChildren.id);
    }

    this.formCheck.setValue(this.papersSelected.length === this.papers.length);
  }

  public eventCardEmitter(event: IEventCard): void {
    this.eventCard(event);
  }

  public async eventCard(event: IEventCard): Promise<void> {
    try {
      switch (event.typeEvent) {
        case TypeEventCardEnum.NEXT:
          this.router.navigateByUrl(this.router.url + '/' + event.id);
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  public getUserLocal(): IUserData | null {
    const userData = this.localStorageService.get(KeyLocalStorage.NPAPER_USER_DATA);
    if (userData === null) {
      return null;
    }

    return JSON.parse(SecretUtil.decrypt(userData));
  }

  private async signDigital(): Promise<void> {
    this.loaderService.showLoader();

    const documentsToSign: SignatureDocumentDigital[] = [];
    const papersSelecionados = this.getPapersSelecionados(this.papers, this.papersSelected) as IPaperDescartados[];
    const papersAgrupadosPorInvolvedGuid: { [involvedGuild: string]: IPaperDescartados[] } = {};

    for (const pPaperSelecionado of papersSelecionados) {
      const involvedGuid = this.signDigitalGetDocumentInvolvedGuid(pPaperSelecionado, this.certificado);
      if (involvedGuid === null) {
        // Paper não possui documento para ser assinado com esse certificado.
        continue;
      }

      if (papersAgrupadosPorInvolvedGuid[involvedGuid] === undefined) {
        papersAgrupadosPorInvolvedGuid[involvedGuid] = [];
      }
      papersAgrupadosPorInvolvedGuid[involvedGuid].push(pPaperSelecionado);
      pPaperSelecionado.documents.forEach((pDoc) => {
        const signatures: SignatureInvolved[] = [];

        pDoc.signatures.filter(pSig => pSig.involved.guid = involvedGuid).forEach((pSig) => {
          if (pSig.status !== SignStatusEnum.naoAssinado) { return; }

          signatures.push(new SignatureInvolved({
            cnpj: pSig.involved.cnpj,
            cpf: pSig.involved.cpf,
            guid: pSig.involved.guid,
            signatureId: pSig.signatureId,
            signedAmount: pSig.signedAmount,
            signerType: pSig.signerType,
            status: pSig.status,
            useECNPJ: pSig.involved.useECNPJ,
            useECPF: pSig.involved.useECPF,
            email: pSig.involved.email,
            title: pSig.title,
            name: pSig.involved.name,
            signatureQuantity: pSig.title.split(',').length,
          }));
        });

        if (signatures.length === 0) { return; }

        documentsToSign.push(new SignatureDocumentDigital(pDoc.guid, pDoc.type, signatures[0].status, true, signatures));
      });
    }

    await this.signatureDigitalService.execute(documentsToSign, this.certificado);
    this.papersSelected = [];
  }

  private getPapersSelecionados(pPapers: IPaper[], pIdPapersSelecionados: number[]): IPaper[] {
    return this.papers.filter(pPaper => pIdPapersSelecionados.includes(pPaper.paperId));
  }

  private signDigitalGetDocumentInvolvedGuid(pPaper: IPaperDescartados, pCertificado: Certificate): string | null {
    let involvedGuid: string | null = null;

    for (const document of pPaper.documents) {
      for (const signature of document.signatures) {
        if (pCertificado.cpf === signature.involved.cpf) {
          involvedGuid = signature.involved.guid;
          break;
        }
        if (involvedGuid !== null) {
          break;
        }
      }
      if (involvedGuid !== null) {
        break;
      }
    }
    return involvedGuid;
  }

  public async assinarDocumentos(): Promise<void> {
    try {
      switch (this.signatureType) {
        case TypeSignatureEnum.DIGITAL:
          await this.signDigital();

          break;
        case TypeSignatureEnum.ELETRONICA:
          await this.realizarAssinaturaEletricaMassa();
          break;
      }
    } catch (error) {
      console.error(error);
      let mensagem: string = 'Erro ao realizar a assinatura.';
      if (error instanceof Error) {
        mensagem = error.message;
      } else if (typeof error === 'string') {
        mensagem = error;
      }
      this.toasterService.showError(mensagem);
    } finally {
      this.loaderService.hideLoader();
      this.toasterService.showSuccess('Documentos assinados com sucesso!');

      if (this.signatureType === TypeSignatureEnum.DIGITAL) {
        this.getPapersDigital(this.certificado);
      } else {
        this.getPapersEletronica();
      }
    }
  }

  private async coletarAssinatura(): Promise<EletronicSignData | null> {
    const assinatura: EletronicSignData | null = await this.modalAssinaturaService.open(this.assinaturaCadastrada);
    return Promise.resolve(assinatura);
  }

  private async setarDocumentosNoStorage(involvedGuid: string, paperId: number): Promise<void> {
    const documentosAssinar = await this.documentHttpService.getDocumentoAssinar(involvedGuid, paperId);

    this.assinarDocumentoStorageService.setPaperDataResolver(
      documentosAssinar.paper,
      involvedGuid,
      Number(paperId),
    );
  }

  private gerarSignatureDocumentsDIGITAL(): SignatureDocumentDigital[] {
    const documentosComInvolved: SignatureDocumentDigital[] = [];

    for (const documento of this.assinarDocumentoStorageService.documentosApresentar) {
      const involveds: SignatureInvolved[] = [];
      documento.involveds.forEach((pInv) => {
        involveds.push(new SignatureInvolved(pInv));
      });
      documentosComInvolved.push(new SignatureDocumentDigital(documento.documentGuid, documento.filename, documento.status, documento.checkAssinar.value, involveds));
    }
    return documentosComInvolved;
  }

  private getDocumentosAssinarEletronic(): SignatureDocument[] {
    const documentosAssinarComEnvolvidos: SignatureDocument[] = [];
    this.assinarDocumentoStorageService.documentosApresentar.forEach((pDoc) => {
      if (pDoc.checkAssinar.value === true) {
        pDoc.involveds.forEach((pInvolved) => {
          if (pInvolved.guid === this.assinarDocumentoStorageService.involvedGuid && pInvolved.status === SignStatusEnum.naoAssinado) {
            documentosAssinarComEnvolvidos.push(new SignatureDocument(pDoc.documentGuid, pDoc.filename, pDoc.status, pDoc.checkAssinar.value, new SignatureInvolved(pInvolved)));
          }
        });
      }
    });
    return documentosAssinarComEnvolvidos;
  }

  private async atualizarAssinaturaCadastrada(pAssinaturaColetada: EletronicSignData): Promise<void> {
    if (this.localStorageService.get(KeyLocalStorage.NPAPER_AUTH_TOKEN) === null || this.assinaturaCadastrada === pAssinaturaColetada.base64) {
      return Promise.resolve();
    }
    const resultadoConfirmacao = await this.modalAtualizarAssinaturaService.open();
    this.loaderService.showLoader();
    if (!!resultadoConfirmacao === true) {
      await this.userHttpService.createUpdateUserSign({ signatureImage: pAssinaturaColetada.base64 });
    }
    this.loaderService.hideLoader();
    return Promise.resolve();
  }

  private async realizarAssinaturaEletricaMassa(): Promise<void> {
    try {
      const assinatura = await this.coletarAssinatura();
      if (assinatura === null) {
        return Promise.resolve();
      }
      const iniciarAssinatura = await this.modalConfirmarInicioAssinaturaService.open(null);

      if (iniciarAssinatura === false) {
        return Promise.resolve();
      }
      const documentosAssinarComEnvolvidos: SignatureDocument[] = [];
      let involvedGuid!: string;
      for (const paperId of this.papersSelected) {
        const paper: any = this.papers.filter((pPaper: any) => pPaper.paperId === paperId);
        involvedGuid = paper[0].documents[0].signatures[0].involved.guid;

        await this.setarDocumentosNoStorage(involvedGuid, Number(paperId));
        documentosAssinarComEnvolvidos.push(...this.getDocumentosAssinarEletronic());
      }

      await this.signatureEletronicService.execute(documentosAssinarComEnvolvidos, assinatura);
      await this.atualizarAssinaturaCadastrada(assinatura);
      // this.assinarDocumentoStorageService.reloadDocuments();
      this.papersSelected = [];
      await this.modalSucessoService.open();
    } catch (error: any) {
      this.loaderAssinatura.hideLoader();
      let mensagemErro = 'Erro ao assinar os documentos';
      if (error instanceof error) {
        mensagemErro = error.mensagem;

      } else if (typeof error === 'string') {
        mensagemErro = error;
      }
      this.toasterService.showError(error.mensagem);
    }
  }
}
