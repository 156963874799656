import { Injectable } from '@angular/core';
import { IPaper, IPaperRequest, IPaperRequestOnly, ITag } from '../../models/paper.model';
import { UrlApiNpaper } from '../../models/url-api-npaper';
import { RequestService } from '../request/request.service';

@Injectable({
  providedIn: 'root'
})
export class PaperHttpService {

  constructor(
    private requestService: RequestService,
  ) { }

  public async getPapers(arrayTags?: ITag[], search?: string): Promise<IPaperRequest> {
    const tagIds = arrayTags && arrayTags.length > 0 ? arrayTags.map(tag => tag.tagId) : [];

    let retorno = await this.requestService
      .setHeaderToken()
      .setHeader('search', search ?? '')
      .setHeader('tag', tagIds.length > 0 ? JSON.stringify(tagIds) : '')
      .get(UrlApiNpaper.PAPERS, '', { isDeleted: false });

    return retorno;
  }

  public async getPapersDiscarted(search?: string): Promise<IPaperRequest> {
    return await this.requestService
      .setHeaderToken()
      .setHeader('search', search ? search : '')
      .get(UrlApiNpaper.PAPERS, '', { 'isDeleted': true });
  }

  public getPaperDetail(id: number): Promise<IPaperRequestOnly> {
    return this.requestService
      .setHeaderToken()
      .get(UrlApiNpaper.PAPERS + '/' + id);
  }

  public async addTagPaper(paperId: number, tags: ITag[]): Promise<void> {
    await this.requestService
      .setHeaderToken()
      .put(`${UrlApiNpaper.PAPERS}/tag/${paperId}`, tags);
  }

  public async removeTag(idPaper: number, idTag?: number): Promise<void> {
    await this.requestService
      .setHeaderToken()
      .put(`${UrlApiNpaper.PAPERS}/tag/${idPaper}/${idTag}`);
  }

  public async deletePaper(idPaper: number): Promise<void> {
    await this.requestService
      .setHeaderToken()
      .delete(`${UrlApiNpaper.PAPERS}/${idPaper}`);
  }

  public async resendPaper(idPaper: number): Promise<void> {
    await this.requestService
      .setHeaderToken()
      .put(`${UrlApiNpaper.PAPERS}/resend/${idPaper}`);
  }

  public async restorePaper(idPaper: number): Promise<void> {
    await this.requestService
      .setHeaderToken()
      .put(`${UrlApiNpaper.PAPERS}/restore/${idPaper}`);
  }

  public async download(idPaper: number): Promise<Blob> {
    return await this.requestService
      .setHeaderToken()
      .requestBlob(UrlApiNpaper.PAPER_DOWNLOAD + idPaper);
  }

  public async getPapersAnonymous(cpf: string, cnpj?: string, search?: string): Promise<any> {
    let params = cpf;
    if (cnpj) {
      params = `${cpf}/${cnpj}`;
    }

    return await this.requestService
      .setHeaderToken()
      .setHeader('search', search ? search : '')
      .get(`${UrlApiNpaper.PAPERS_NOT_LOGGED}/${params}`);
  }

  public async getPapersLoteCertificadoDigital(cpf: string, cnpj?: string, search?: string): Promise<IPaper[]> {
    let params = cpf;
    if (cnpj) {
      params = `${cpf}/${cnpj}`;
    }

    return await this.requestService
      .setHeaderToken()
      .setHeader('search', search ? search : '')
      .get(`${UrlApiNpaper.PAPERS}/tosign/${params}`);
  }

  public async getPapersLoteAssinaturaEletronica(cpfCnpj: string, search?: string): Promise<any> {
    return await this.requestService
      .setHeaderToken()
      .setHeader('search', search ? search : '')
      .get(`${UrlApiNpaper.PAPERS}/batch/${cpfCnpj}`);
  }

  public getPaperAnonymousDetail(id: number): Promise<IPaperRequestOnly> {
    return this.requestService
      .setHeaderToken()
      .get(UrlApiNpaper.PAPER_NOT_LOGGED + '/' + id);
  }
}
