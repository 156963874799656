import { notification, userLogged } from '../../../../shared/models/nav';
import { NavNService } from './nav.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthLayoutService } from '../../auth-layout.service';
import { ModalDismissReasons, NgbDropdown, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { IUserData, User } from 'src/app/shared/models/user.model';
import { KeyLocalStorage } from 'src/app/shared/models/key-local-storage';
import { SecretUtil } from 'src/app/shared/utils/secret-util';
import { UserService } from 'src/app/shared/services/user/user.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AccountTypeName } from 'src/app/shared/enum/account-type';
import { ModalConfirmLogoutService } from 'src/app/shared/components/modal-confirm-logout/modal-confirm-logout.service';
import { ModalEditNameService } from 'src/app/shared/components/modal-edit-name/modal-edit-name.service';

interface IUserNavigate {
  test: boolean;
  prePaid: boolean;
  signaturePlan: number;
  signatureQtd: number;
  testPeriod: number | null;
  accountType: string;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @ViewChild('#dropdownUser') public dropdown: NgbDropdown;

  searchs: string[] = this.navbarService.getSearch();
  notifications: notification[] = this.navbarService.getNotification();
  user: userLogged = this.navbarService.getUserLogged();

  isGradientScroll: boolean = true;
  public isVisaoTablet = false;
  public isVisaoMobile = false;
  public menuAberto: boolean = false;

  public userAuth: IUserData | any;
  public isAccount = false;
  public customerSelecionado: string | undefined = '';
  public userNavigate = {} as IUserNavigate;
  AccountTypeName = AccountTypeName;
  closeResult = '';

  public userId: any;


  constructor(
    private navbarService: NavNService,
    private localStorageService: LocalStorageService,
    private router: Router,
    public authLayoutService: AuthLayoutService,
    private modalService: NgbModal,
    private authService: AuthService,
    private userService: UserService,
    private modalConfirmLogout: ModalConfirmLogoutService,
    private modalEditNameUser: ModalEditNameService,
    private activeRoute: ActivatedRoute,
  ) {
    // config.backdrop = '';
  }

  async ngOnInit() {
    this.isVisaoTablet = false;
    this.isVisaoMobile = false;

    if (window.innerWidth <= 768 && window.innerWidth > 576) {
      this.isVisaoTablet = true;
    }

    if (window.innerWidth <= 576) {
      this.isVisaoMobile = true;
    }

    const salvaResultado = this.getUserLocal();
    if (salvaResultado === null) {
      throw new Error('Usuário não encontrado!');
    }
    this.userAuth = salvaResultado;

    this.userAuth = this.getUserLocal();

    if (this.userAuth) {
      this.userNavigate = {
        test: this.userAuth.customerTest,
        prePaid: this.userAuth.prePaid,
        signaturePlan: this.userAuth.signaturePlan,
        signatureQtd: this.userAuth.signatureQtd,
        testPeriod: this.userAuth.testPeriod,
        accountType: this.getTipoConta(this.userAuth)
      }
    }

    this.customerSelecionado = this.userAuth?.customer;
  }

  private getTipoConta(user: IUserData) {
    let tipoConta: string = '';
    if (user.customerTest) {
      tipoConta = AccountTypeName.Test;
    }
    if (user.prePaid) {
      tipoConta = AccountTypeName.PrePaid;
    }
    if (user.test === false && user.prePaid === false) {
      tipoConta = AccountTypeName.MonthlyPayment
    }
    return tipoConta;
  }

  openMenuVertical(content: any) {
    if (!this.menuAberto) {
      this.menuAberto = true;
      this.modalService.open(content, { windowClass: 'full-modal-menu' }).result.then(
        (result) => {
        },
        (reason) => {
          if (reason === ModalDismissReasons.BACKDROP_CLICK || reason === ModalDismissReasons.ESC) {
            this.menuAberto = false;
          }
        });
    } else {
      this.menuAberto = false;
      this.modalService.dismissAll('Cross click');
    }
  }

  closeMenuVertical() {
    this.menuAberto = false;
  }

  navegar(rota: string) {
    this.router.navigate([rota]);
  }

  onScroll(event: any) {
    let elementHeight = event.target.scrollHeight - event.target.offsetHeight;
    let scrollPosition = Math.round(event.target.scrollTop);

    if (elementHeight <= scrollPosition) {
      return (this.isGradientScroll = false);
    }
    return (this.isGradientScroll = true);
  }

  public async logout() {
    const result = await this.modalConfirmLogout.open();
    if (result) {
      this.localStorageService.remove(KeyLocalStorage.NPAPER_CUSTOMER_DEFAULT);
      this.localStorageService.remove(KeyLocalStorage.NPAPER_USER_DATA);
      this.localStorageService.remove(KeyLocalStorage.NPAPER_AUTH_TOKEN);
      this.router.navigate(['/', 'login'], {
        relativeTo: this.activeRoute,
      });
    }
  }

  public async trocarUser(index: number) {
    this.customerSelecionado = this.userAuth?.customers[index].name;

    const resultDataUser = await this.userService.getDataUser(
      this.userAuth?.customers[index].token
    );

    const user = new User(resultDataUser.user);

    this.authService.setToken(user.token);
    this.authService.setUserLocal(user);

    window.location.reload();
  }

  private getUserLocal(): IUserData | null {
    const userData = this.localStorageService.get(KeyLocalStorage.NPAPER_USER_DATA);
    if (userData === null) {
      return null;
    }

    this.isAccount = true;
    return JSON.parse(SecretUtil.decrypt(userData));
  }


  public async editName(userId: string): Promise<void> {
    const resultModalEditNameUser = await this.modalEditNameUser.openEdit(userId);

    if (resultModalEditNameUser.confirm === false || resultModalEditNameUser.nome === null) {
      return;
    }
    this.userAuth.name = resultModalEditNameUser.nome;

  }
}
