import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NavNService } from 'src/app/core/auth-layout/components/nav/nav.service';
import { LoadingService } from 'src/app/shared/components/settings/loading/loading.service';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { TypeEventCardEnum, TypeEventKanbanEnum } from 'src/app/shared/enum/event-card.enum';
import { LayoutVisaoEnum } from 'src/app/shared/enum/layout-visao.enum';
import { StatusSignedEnum } from 'src/app/shared/enum/statusSigned.enum';
import { IEventCard, IEventKanban } from 'src/app/shared/models/Event-card.model';
import { ISelectOptionAssunto } from 'src/app/shared/models/input';
import { IKanbanField } from 'src/app/shared/models/kanban-documents.model';
import { IPaper, IPaperKanban, ITag } from 'src/app/shared/models/paper.model';
import { IValorLabel } from 'src/app/shared/models/valor-label';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { PaperHttpService } from 'src/app/shared/services/paper/paper.http.service';
import TagUtil from 'src/app/shared/utils/paper-tag.util';
import { ModalConfirmDescartPaperService } from '../../../shared/components/modal-confirm-descart-paper/modal-confirm-descart-paper.service';
import { kanbanField } from '../components/constante/send.constante.component';
import { DownloadUtil } from './../../../shared/utils/download.util';

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.scss'],
})
export class SendComponent implements OnInit {
  public kanbanField = kanbanField;
  public kanbanSelected?: IKanbanField;
  public papers: IPaper[] = [];
  public tagFilter: ITag[] = [];
  searchs: string[] = this.navbarService.getSearch();
  public isVisaoMobile: boolean = false;
  public openInputSearchAssunto: boolean = false;
  public isVisibleTitle: boolean = true;
  public isPaddingSelect: boolean = true;
  private papersSearch: Array<IPaper> = new Array();
  public optionAssuntoSearch: Array<ISelectOptionAssunto> = new Array();
  public formControlSelectFilterInAssunto: FormControl;

  constructor(
    private navbarService: NavNService,
    private paperHttpService: PaperHttpService,
    private router: Router,
    private modalConfirmService: ModalConfirmDescartPaperService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private layoutVisao: LayoutService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE);
    this.formControlSelectFilterInAssunto = new FormControl(null);

    this.kanbanSelected = this.kanbanField[0];
    this.getPapers(this.tagFilter, this.formControlSelectFilterInAssunto.value);
  }

  public async eventCard(event: IEventCard): Promise<void> {
    try {
      switch (event.typeEvent) {
        case TypeEventCardEnum.DELETETAG:
          if (event.tagsSelected?.length === 0) {
            this.loadingService.showLoader();
            await this.paperHttpService.removeTag(event.id, event.idTag);
            this.toasterService.showSuccess('Marcador removido com sucesso');
          } else {
            /** Limpar todas as tags */
            if (event.tagsSelected) {
              for (const tag of event.tagsSelected) {
                await this.paperHttpService.removeTag(event.id, tag.tagId);
              }

              this.toasterService.showSuccess('Os marcadores foram removidos com sucesso');
            }
          }
          break;
        case TypeEventCardEnum.TAG:
          if (event.tagsSelected) {
            this.addTagsPaper(event.id, event.tagsSelected);
          }
          break;
        case TypeEventCardEnum.TRASH:
          this.modalConfirmService.showModalConfirm([event.id]);
          break;
        case TypeEventCardEnum.SEND:
          this.loadingService.showLoader();
          await this.paperHttpService.resendPaper(event.id);
          this.toasterService.showSuccess('Documento reenviado com sucesso!');
          break;
        case TypeEventCardEnum.NEXT:
          this.router.navigateByUrl(this.router.url + '/' + event.id);
          break;
        case TypeEventCardEnum.DOWNLOAD:
          this.loadingService.showLoader();
          DownloadUtil.downloadFile(await this.paperHttpService.download(event.id), event.id.toString());
          this.toasterService.showSuccess('Download realizado com sucesso!');
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.getPapers(this.tagFilter, this.formControlSelectFilterInAssunto.value);
    }
  }

  public async eventKanban(event: IEventKanban): Promise<void> {
    try {
      switch (event.typeEvent) {
        case TypeEventKanbanEnum.SEND:
          this.loadingService.showLoader();
          for (const paperId of event.papers) {
            await this.paperHttpService.resendPaper(paperId);
          }
          this.toasterService.showSuccess('Documento reenviado com sucesso!');
          break;
        case TypeEventKanbanEnum.TRASH:
          this.modalConfirmService.showModalConfirm(event.papers);
          break;
        case TypeEventKanbanEnum.TAG:

          if (event.tagsSelected === undefined || event.tagsSelected.length === 0) {
            break;
          }

          for (const paperId of event.papers) {
            await this.addTagsPaper(paperId, event.tagsSelected);
          }
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.getPapers(this.tagFilter, this.formControlSelectFilterInAssunto.value);
    }
  }

  public async getPapers(tagFilter: ITag[], search: string): Promise<void> {
    try {
      this.loadingService.showLoader();
      const { papers } = (await this.paperHttpService.getPapers(tagFilter, search));
      this.papers = papers;

      const assinados = this.formatPapers(papers.filter((paper) => paper.status === StatusSignedEnum.SIGNED));
      const naoAssinados = this.formatPapers(papers.filter((paper) => paper.status === StatusSignedEnum.UNSIGNED));
      const parcialmenteAssinados = this.formatPapers(papers.filter((paper) => paper.status === StatusSignedEnum.PARTIALLY_SIGNED));

      this.atributeToKanban('Totalmente assinados', assinados);
      this.atributeToKanban('Não assinados', naoAssinados);
      this.atributeToKanban('Parcialmente assinados', parcialmenteAssinados);
    } catch (error) {
      console.error(error);
      this.toasterService.showError('Não foi possivel buscar documentos!');
    }
    finally {
      this.loadingService.hideLoader();
    }
  }

  private async addTagsPaper(paperId: number, pTags: ITag[]) {
    try {
      this.loadingService.showLoader();

      /** Remover tags e incluir tags selecionadas */
      await this.removerAllTags(paperId);

      /** Add tags selecionadas */
      await this.paperHttpService.addTagPaper(paperId, pTags);

      this.toasterService.showSuccess('Marcador vinculado com sucesso!');
    } catch (error) {
      this.toasterService.showError('Não foi adicionar tags ao documento!');
    } finally {
      this.updateList();
      this.loadingService.hideLoader();
    }
  }

  private async removerAllTags(paperId: number): Promise<void> {
    const paper = this.papers.find(paperFind => paperFind.paperId === paperId);

    if (!paper) {
      this.toasterService.showError('Documento não encontrado na lista');
      throw new Error(`Documento não encontrado na lista: documento (id: ${paperId}) não encontrado na lista de documento na area de enviados`);
    }

    for (const tag of paper.tags) {
      await this.paperHttpService.removeTag(paperId, tag.tagId);
    }

    return Promise.resolve();
  }

  private atributeToKanban(title: string, papers: IPaperKanban[]): void {
    const index = this.kanbanField.findIndex(field => field.title === title);

    this.kanbanField[index].papers = papers;
  }

  private formatPapers(papers: IPaper[]): IPaperKanban[] {
    return papers.map(paper => ({
      id: paper.paperId,
      typeSignature: TagUtil.formatTypeSignature(paper.signatureType),
      date: paper.createdAt,
      signed: paper.qtdAssinados,
      totalSign: paper.qtdTotalAssinaturas,
      subject: paper.subject,
      fullySigned: paper.status === StatusSignedEnum.SIGNED,
      tags: TagUtil.formatTag(paper.tags)
    }));
  }

  public async updateList(): Promise<void> {
    this.getPapers(this.tagFilter, this.formControlSelectFilterInAssunto.value);
  }

  public changeField(field: IKanbanField): void {
    this.kanbanSelected = field;
  }

  public isFieldSelected(field: IKanbanField): boolean {
    return this.kanbanSelected === field;
  }

  public eventCardEmitter(event: IEventCard): void {
    this.eventCard(event);
  }

  public eventKanbanEmitter(event: IEventKanban): void {
    this.eventKanban(event);
  }

  public filterTag(tagSelect: ITag[] | null) {
    this.tagFilter = tagSelect === null ? [] : tagSelect;
    this.getPapers(this.tagFilter, this.formControlSelectFilterInAssunto.value);
  }

  public onFocusInputSearchAssunto(event: boolean): void {
    this.openInputSearchAssunto = event;
    this.isVisibleTitle = false;
    this.isPaddingSelect = false;
  }

  public onAssuntoSelect(event: IValorLabel): void {
    const resultConvertIdStringInNumber = Number(event.valor);
    const paperFilter: IPaper[] = [];
    this.papersSearch.forEach(paper => {
      if (resultConvertIdStringInNumber === paper.paperId) {
        paperFilter.push(paper);
      }
    });

    this.preencherKanban(paperFilter);
  }

  public generateListOptions(): void {
    const optionSelect: ISelectOptionAssunto[] = [];

    this.papersSearch.forEach(paper => {
      optionSelect.push({
        assunto: paper.subject,
        id: paper.paperId,
      });
    });

    this.optionAssuntoSearch = optionSelect;
  }

  private preencherKanban(papers: IPaper[]) {
    const assinados = this.formatPapers(papers.filter((paper) => paper.status === StatusSignedEnum.SIGNED));
    const naoAssinados = this.formatPapers(papers.filter((paper) => paper.status === StatusSignedEnum.UNSIGNED));
    const parcialmenteAssinados = this.formatPapers(papers.filter((paper) => paper.status === StatusSignedEnum.PARTIALLY_SIGNED));

    this.atributeToKanban('Totalmente assinados', assinados);
    this.atributeToKanban('Não assinados', naoAssinados);
    this.atributeToKanban('Parcialmente assinados', parcialmenteAssinados);
  }

  public async searchDocumentAssunto(search: string): Promise<void> {
    try {
      const { papers } = (await this.paperHttpService.getPapers(this.tagFilter, search));
      this.papersSearch = papers;
      this.preencherKanban(this.papersSearch);
    } catch (error) {
      console.log(error);
    }

  }
}
