import { ToasterComponent } from './components/toaster/components/toaster/toaster.component';
// CORE
import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// LIBS
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { AccordionCardComponent } from './components/accordion/accordion-card/accordion-card.component';
import { AccordionItemsComponent } from './components/accordion/accordion-items/accordion-items.component';
import { AvatarOneLetterComponent } from './components/avatar-one-letter/avatar-one-letter.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BehaviorAvatarComponent } from './components/behavior-avatar/behavior-avatar.component';
import { BehaviorSelectComponent } from './components/behavior-select/behavior-select.component';
// COMPONENTS
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CardClientComponent } from './components/card-client/card-client.component';
import { CardCompanyMobileComponent } from './components/card-company-mobile/card-company-mobile.component';
import { CardCompanyComponent } from './components/card-company/card-company.component';
import { CardDottedEdgesComponent } from './components/card-dotted-edges/card-dotted-edges.component';
import { CardExtractSignatureAndShippingDetailsComponent } from './components/card-extract-signature-and-shipping-details/card-extract-signature-and-shipping-details.component';
import { CardKanbanComponent } from './components/card-kanban/card-kanban.component';
import { CardSubscribersMobileComponent } from './components/card-subscribers-mobile/card-subscribers-mobile.component';
import { CardSubscribersComponent } from './components/card-subscribers/card-subscribers.component';
import { CardUserAdminComponent } from './components/card-user-admin/card-user-admin.component';
import { CardWithIconTitleRadioComponent } from './components/card-with-icon-title-radio/card-with-icon-title-radio.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ContainerHeaderComponent } from './components/container-header/container-header.component';
import { DataPickerComponent } from './components/data-picker/data-picker.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { InputTextareaComponent } from './components/input-textarea/input-textarea.component';
import { InputComponent } from './components/input/input.component';
import { KanbanFieldComponent } from './components/kanban-field/kanban-field.component';
import { ModalInstallLacunaWebPkiComponent } from './components/lacuna-web-pki/components/modal-install-lacuna-web-pki/modal-install-lacuna-web-pki.component';
import { MessageControlErroComponent } from './components/message-control-erro/message-control-erro.component';
import { ModalAddEditResponsibilityComponent } from './components/modal-add-edit-responsibility/modal-add-edit-responsibility.component';
import { ModalAddEditResponsibilityService } from './components/modal-add-edit-responsibility/modal-add-edit-responsibility.service';
import { ModalBlockCustomerComponent } from './components/modal-block-customer/modal-block-customer.component';
import { ModalCompanyComponent } from './components/modal-company/modal-company.component';
import { ModalConfirmDeleteCompanyComponent } from './components/modal-confirm-delete-company/modal-confirm-delete-company.component';
import { ModalConfirmDeleteComponent } from './components/modal-confirm-delete/modal-confirm-delete.component';
import { ModalConfirmDescartPaperComponent } from './components/modal-confirm-descart-paper/modal-confirm-descart-paper.component';
import { ModalDeleteResponsibilityComponent } from './components/modal-delete-responsibility/modal-delete-responsibility.component';
import { ModalDeleteResponsibilityService } from './components/modal-delete-responsibility/modal-delete-responsibility.service';
import { ModalDeleteComponent } from './components/modal-delete/modal-delete.component';
import { ModalRemoveCustomerAdminComponent } from './components/modal-remove-customer-admin/modal-remove-customer-admin.component';
import { ModalSubscribersComponent } from './components/modal-subscribers/modal-subscribers.component';
import { RadioComponent } from './components/radio/radio.component';
import { SelectMultipleOptionsComponent } from './components/select-multiple-options/select-multiple-options.component';
import { SelectNormalComponent } from './components/select-normal/select-normal.component';
import { SelectResponsibilityComponent } from './components/select-responsibility/select-responsibility.component';
import { SelectSearchCertificateComponent } from './components/select-search-certificate/select-search-certificate.component';
import { SelectSearchCustomizedComponent } from './components/select-search-customized/select-search-customized.component';
import { SelectSearchWithAvatarComponent } from './components/select-search-with-avatar/select-search-with-avatar.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { SelectSetaComponent } from './components/select-seta/select-seta.component';
import { ContentInfoSendDetailComponent } from './components/send-detail/content-info-send-detail/content-info-send-detail.component';
import { RegisterSendDetailMailComponent } from './components/send-detail/register-send-detail-mail/register-send-detail-mail.component';
import { RegisterSendDocumentComponent } from './components/send-detail/register-send-document/register-send-document.component';
import { SendDetailComponent } from './components/send-detail/send-detail.component';
import { LoadingSignatureComponent } from './components/settings/loading-signature/loading-signature.component';
import { LoadingComponent } from './components/settings/loading/loading.component';
import { ProgressComponent } from './components/settings/progress/progress.component';
import { SwitchComponent } from './components/switch/switch.component';
import { BagdeTagComponent } from './components/tag/bagde-tag/bagde-tag.component';
import { ListTagComponent } from './components/tag/list-tag/list-tag.component';
import { ModalTagComponent } from './components/tag/modal-tag/modal-tag.component';
import { ToasterLayoutComponent } from './components/toaster/toaster-layout.component';
import { ValidationErrorMessageComponent } from './components/validation-error-message/validation-error-message.component';
// PIPES
import { SelectUserWithSearchComponent } from './components/select-user-with-search/select-user-with-search.component';
import { CurrencyMaskModule } from './directive/currency-mask/currency-mask.module';
import { AngleUpDownPipe } from './pipes/angle-up-down.pipe';
import { CalculateByteInGigabytePipe } from './pipes/calculate-byte/calculate.byte-gigabyte.pipe';
import { CalculateByteInKiloBytePipe } from './pipes/calculate-byte/calculate.byte-kilobyte.pipe';
import { CalculateByteInMegabytePipe } from './pipes/calculate-byte/calculate.byte-megabyte.pipe';
import { CalculateByteInPetabytePipe } from './pipes/calculate-byte/calculate.byte-petabyte.pipe';
import { CalculateByteInTerabytePipe } from './pipes/calculate-byte/calculate.byte-terabyte.pipe';
import { CalculateBytePipe } from './pipes/calculate-byte/calculate.byte.pipe';
import { FirstNamePipe } from './pipes/first-name.pipe';
import { NgbDatePTParserFormatterService } from './services/datePicker/ngb-date-ptparser-formatter.service';
import { SelectHtmlComponent } from './components/select-html/select-html.component';
import { CardDocumentsComponent } from './components/card-documents/card-documents.component';
import { KanbanDocumentsFieldComponent } from './components/kanban-documents-field/kanban-documents-field.component';
import { AssinaturaDesenhoComponent } from './components/assinatura-desenho/assinatura-desenho.component';
import { AssinaturaDigitarComponent } from './components/assinatura-digitar/assinatura-digitar.component';
import { ModalCancelUpdateComponent } from './components/modal-cancel-update/modal-cancel-update.component';
import { CpfCnpjPipe } from './pipes/cpfCnpj.pipe';
import { BotaoConhecaNpaperComponent } from './components/botao-conheca-npaper/botao-conheca-npaper.component';
import { ModalLocalizeCertificateComponent } from './components/modal-localize-certificate/modal-localize-certificate.component';
import { CardDocumentsLoteComponent } from './components/card-documents-lote/card-documents-lote.component';
import { ModalConfirmLogoutComponent } from './components/modal-confirm-logout/modal-confirm-logout.component';
import { ModalConfirmLogoutService } from './components/modal-confirm-logout/modal-confirm-logout.service';
import { ModalConfirmDeleteTagComponent } from './components/modal-confirm-delete-tag/modal-confirm-delete-tag.component';
import { ModalConfirmDeleteTagService } from './components/modal-confirm-delete-tag/modal-confirm-delete-tag.service';
import { ModalEditNameComponent } from './components/modal-edit-name/modal-edit-name.component';
import { ModalTermosDeUsoComponent } from './components/modal-termos-de-uso/modal-termos-de-uso.component';
import { AccordionSignatureComponent } from './components/accordion-signature/accordion-signature.component';
import { IconesComponent } from './components/icones/icones.component';
import { AccordionSignatureHeaderComponent } from './components/accordion-signature/components/accordion-signature-header/accordion-signature-header.component';
import { AccordionSignatureContentComponent } from './components/accordion-signature/components/accordion-signature-content/accordion-signature-content.component';
import { BotaoComponent } from './components/botao/botao.component';
import { PdfViewerNativeComponent } from './components/pdf-viewer-native/pdf-viewer-native.component';
import { InputSearchComponent } from './components/input-search/input-search.component';

registerLocaleData(localePt);

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    FormsModule,
    CurrencyMaskModule],
  declarations: [
    ModalLocalizeCertificateComponent,
    AngleUpDownPipe,
    CpfCnpjPipe,
    FirstNamePipe,
    CardExtractSignatureAndShippingDetailsComponent,
    LoadingComponent,
    ProgressComponent,
    ModalTagComponent,
    ListTagComponent,
    BagdeTagComponent,
    ModalDeleteComponent,
    SendDetailComponent,
    ContentInfoSendDetailComponent,
    RegisterSendDetailMailComponent,
    RegisterSendDocumentComponent,
    ValidationErrorMessageComponent,
    BreadcrumbComponent,
    CardKanbanComponent,
    KanbanFieldComponent,
    ToasterLayoutComponent,
    ToasterComponent,
    InputComponent,
    MessageControlErroComponent,
    CheckboxComponent,
    RadioComponent,
    LoadingSignatureComponent,
    SelectNormalComponent,
    SwitchComponent,
    SelectSetaComponent,
    SelectSearchComponent,
    ModalInstallLacunaWebPkiComponent,
    SelectSearchCustomizedComponent,
    BehaviorSelectComponent,
    SelectSearchCertificateComponent,
    DataPickerComponent,
    CardDottedEdgesComponent,
    CardClientComponent,
    ModalBlockCustomerComponent,
    SelectMultipleOptionsComponent,
    BadgeComponent,
    CardUserAdminComponent,
    ModalRemoveCustomerAdminComponent,
    SelectSearchWithAvatarComponent,
    SelectHtmlComponent,
    AvatarComponent,
    AvatarOneLetterComponent,
    BehaviorAvatarComponent,
    CardDocumentsComponent,
    CardDocumentsLoteComponent,
    InputFileComponent,
    CalculateByteInKiloBytePipe,
    CalculateByteInMegabytePipe,
    CalculateByteInGigabytePipe,
    CalculateByteInTerabytePipe,
    CalculateByteInPetabytePipe,
    CalculateBytePipe,
    ContainerHeaderComponent,
    CardWithIconTitleRadioComponent,
    SelectResponsibilityComponent,
    ModalAddEditResponsibilityComponent,
    ModalDeleteResponsibilityComponent,
    KanbanDocumentsFieldComponent,
    InputTextareaComponent,
    AccordionCardComponent,
    AccordionItemsComponent,
    BadgeComponent,
    ModalConfirmDescartPaperComponent,
    AssinaturaDesenhoComponent,
    AssinaturaDigitarComponent,
    CardDottedEdgesComponent,
    ModalSubscribersComponent,
    CardCompanyComponent,
    CardSubscribersMobileComponent,
    CardSubscribersComponent,
    ModalCompanyComponent,
    ModalConfirmDeleteComponent,
    CardCompanyMobileComponent,
    ModalConfirmDeleteCompanyComponent,
    ModalCancelUpdateComponent,
    ContainerHeaderComponent,
    SelectUserWithSearchComponent,
    KanbanDocumentsFieldComponent,
    BotaoConhecaNpaperComponent,
    ModalConfirmLogoutComponent,
    ModalConfirmDeleteTagComponent,
    ModalEditNameComponent,
    ModalTermosDeUsoComponent,
    AccordionSignatureComponent,
    IconesComponent,
    AccordionSignatureHeaderComponent,
    AccordionSignatureContentComponent,
    BotaoComponent,
    PdfViewerNativeComponent,
    InputSearchComponent,
  ],
  exports: [
    ModalLocalizeCertificateComponent,
    CommonModule,
    AngleUpDownPipe,
    CpfCnpjPipe,
    FirstNamePipe,
    RouterModule,
    NgbModule,
    CardExtractSignatureAndShippingDetailsComponent,
    LoadingComponent,
    ProgressComponent,
    ModalTagComponent,
    ListTagComponent,
    BagdeTagComponent,
    ModalDeleteComponent,
    SendDetailComponent,
    ContentInfoSendDetailComponent,
    RegisterSendDetailMailComponent,
    RegisterSendDocumentComponent,
    ValidationErrorMessageComponent,
    BreadcrumbComponent,
    CardKanbanComponent,
    KanbanFieldComponent,
    ToasterLayoutComponent,
    ToasterComponent,
    InputComponent,
    CheckboxComponent,
    RadioComponent,
    LoadingSignatureComponent,
    SelectNormalComponent,
    SwitchComponent,
    SelectSetaComponent,
    SelectSearchComponent,
    SelectSearchCustomizedComponent,
    SelectSearchCertificateComponent,
    DataPickerComponent,
    CardDottedEdgesComponent,
    CardClientComponent,
    SelectMultipleOptionsComponent,
    CardUserAdminComponent,
    SelectSearchWithAvatarComponent,
    SelectHtmlComponent,
    AvatarComponent,
    AvatarOneLetterComponent,
    CardDocumentsComponent,
    CardDocumentsLoteComponent,
    InputFileComponent,
    CalculateByteInKiloBytePipe,
    CalculateByteInMegabytePipe,
    CalculateByteInGigabytePipe,
    CalculateByteInTerabytePipe,
    CalculateByteInPetabytePipe,
    CalculateBytePipe,
    ContainerHeaderComponent,
    CardWithIconTitleRadioComponent,
    SelectResponsibilityComponent,
    InputTextareaComponent,
    AccordionCardComponent,
    AccordionItemsComponent,
    BadgeComponent,
    ModalConfirmDescartPaperComponent,
    AssinaturaDesenhoComponent,
    AssinaturaDigitarComponent,
    ModalSubscribersComponent,
    CardCompanyComponent,
    CardSubscribersMobileComponent,
    CardSubscribersComponent,
    ModalCompanyComponent,
    ModalConfirmDeleteComponent,
    CardCompanyMobileComponent,
    ModalConfirmDeleteCompanyComponent,
    ContainerHeaderComponent,
    MessageControlErroComponent,
    SelectUserWithSearchComponent,
    CurrencyMaskModule,
    KanbanDocumentsFieldComponent,
    BotaoConhecaNpaperComponent,
    ModalConfirmLogoutComponent,
    ModalConfirmDeleteTagComponent,
    ModalTermosDeUsoComponent,
    AccordionSignatureComponent,
    IconesComponent,
    AccordionSignatureHeaderComponent,
    AccordionSignatureContentComponent,
    BotaoComponent,
    PdfViewerNativeComponent,
    InputSearchComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    [
      {
        provide: NgbDateParserFormatter,
        useClass: NgbDatePTParserFormatterService,
      },
    ],
    ModalAddEditResponsibilityService,
    ModalDeleteResponsibilityService,
    ModalConfirmLogoutService,
    ModalConfirmDeleteTagService
  ],
  // entryComponents: [ModalDeleteComponent],
})
export class SharedModule { }
